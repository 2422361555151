/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-tel-input .form-control {
  @apply w-full py-6 !text-base;
  width: 100% !important;
}

.opt-container {
  @apply flex justify-between gap-2;
}

.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}

.input {
  padding-left: 0.7rem !important;
}

/* dropdown */

.multiselect {
  width: 100%;
}

.selectBox {
  position: absolute;
}

.selectBox select {
  width: 100%;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Scrollbar css inside T&C of upload audio */
.termsContent {
  scrollbar-width: thin;
  scrollbar-color: #ff6c2f #ffffff0d;
  overflow-y: auto;
  max-height: 700px;
}

.termsContent::-webkit-scrollbar {
  width: 2px;
}

.termsContent::-webkit-scrollbar-thumb {
  background-color: #ff6c2f;
  border-radius: 10px;
}

.termsContent::-webkit-scrollbar-track {
  background-color: #ffffff0d;
  border-radius: 20px;
}
.sigCanvas {
  border: 1px solid #ffffff;
  border-radius: 5px;
}

/* css for Drop down */
.custom-multi-select {
  width: 35rem;
  font-family: poppins;
  background-color: #4c4c4c !important;
  border: 1px solid #4c4c4c;
  border-radius: 5px;
}

.dropdown-header {
  padding: 10px;
  cursor: pointer;
  color: #fff;
}

.dropdown-list {
  width: 35rem;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #4c4c4c;
  background: #4c4c4c;
  z-index: 1000;
  color: #fff;
}

.dropdown-item {
  padding: 4px;
  /* padding-left: 10px; */
  cursor: pointer;
  color: #fff;
}

.dropdown-item:hover,
.dropdown-item.selected {
  background-color: #0077b9;
}

.profile_bar {
  height: 60%;
}

.image-wrapper {
  /* position:relative; */
  /* display: inline-block; */
  width: 200px;
  height: 200px;
  border: 2px solid #ff6c2f;
  border-radius: 5px;
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
}

.button-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin-right: -33%;
  border-radius: 3px;
}

.placeholder {
  font-size: 18px;
  color: #aaa;
}

.file-input {
  /* position: absolute; */
  /* width: 100%;
    height: 100%; */
  opacity: 0;
  cursor: pointer;
}

/* Profile picture CSS */

.profile_bar {
  height: 60%;
}

.image-wrapper {
  /* position:relative; */
  /* display: inline-block; */
  width: 200px;
  height: 200px;
  border: 2px solid #ff6c2f;
  border-radius: 5px;
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
}

.button-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin-right: -33%;
  border-radius: 3px;
}

.placeholder {
  font-size: 18px;
  color: #aaa;
}

.file-input {
  /* position: absolute; */
  /* width: 100%;
    height: 100%; */
  opacity: 0;
  cursor: pointer;
}

/* Address Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color:#e600000d; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 10; */
  z-index: 1000;
}
.labelHeading {
  font-size: 14px;
}

.popup-content {
  background-color: #202020;
  padding: 20px;
  border-radius: 12px;
  width: 38%;
  height: auto;
  /* box-shadow: 0 2px 10px #ffffff0d; */
  position: relative;
  font-family: Poppins;
  color: #ff6c2f;
  /* opacity: 1; */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.button-group button {
  margin-top: 5%;
  margin-bottom: 3%;
  padding: 8px 16px;
  border: none;
  width: 15%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  background-color: #ff6c2f;
  color: white;
}

.button-group button:hover {
  background-color: #e44c06d2;
}

.button-popup:hover {
  background-color: #e44c06d2;
}

/* Account Details CSS */
body {
  background-image: linear-gradient(to left, #0c0c0c, #131212);
}
.container {
  display: flex;
}

.container1 {
  margin-left: 12%;
  margin-right: 12%;
  margin-bottom: 10%;
  border-radius: 5px;
  width: auto;
  height: 60%;
  background-color: #ffffff0d;
  display: flex;
  border-radius: 10px;
  /* overflow-y: scroll */
}

hr {
  border: 1.5px solid #ff6c2f;
  border-radius: 5px;
  width: auto;
  margin-left: auto;
}
.formDetails {
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
}
input {
  outline: none;
  /* padding-left: 0.7rem !important; */
}
input[type="text"]::placeholder,
input[type="url"]::placeholder,
input[type="email"]::placeholder {
  padding-left: 0.7rem;
  color: #7f7f7f;
}

.editButton {
  width: 100px;
  height: 20px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.file-name {
  max-width: 10%;
  max-height: 10%;
  font-size: 13px;
  font-weight: normal;
  font-family: Poppins;
  color: #ebedf0;
  text-decoration: underline;
  padding-left: 5%;
  word-break: break-all;
  text-align: center;
}

/* ------ css for Upload Button in Bank Details form */
.btn-primary {
  background-color: #4c4c4c;
  font: #ffffff;
}

.btn {
  -moz-user-select: none;
  border: 1px solid transparent;
  border-radius: 5px;
  display: inline-block;
  width: 20%;
  height: 2.8rem;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.375rem 0.75rem;
  font-family: poppins;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

/* Addrress PopUp CSS */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.labelHeading {
  font-size: 14px;
}

.popup-content {
  background-color: #202020;
  padding: 20px;
  border-radius: 12px;
  width: 38%;
  height: auto;
  position: relative;
  font-family: Poppins;
  color: #ff6c2f;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.button-group button {
  margin-top: 5%;
  margin-bottom: 3%;
  padding: 8px 16px;
  border: none;
  width: 15%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  background-color: #ff6c2f;
  color: white;
}

.button-group button:hover {
  background-color: #e44c06d2;
}

.button-popup:hover {
  background-color: #e44c06d2;
}

/* css for table */
.autnh-1 {
  padding: 16px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.autnr {
  padding: 10px;
  font-size: 11px;
  font-weight: 200;
  color: white;
  background-color: #3b3b3b;
  text-align: center;
  vertical-align: middle;
}

.mis-head {
  height: 3rem;
  padding: 0px 20px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.mis-data {
  height: 3rem;
  padding: 6px 10px;
  font-size: 11px;
  font-weight: 200;
  color: white;
  background-color: #3b3b3b;
  text-align: center;
  vertical-align: middle;
}

/* Styles for better alignment [signIn popup] */
.phone-input {
  display: flex;
  align-items: center;
}

.phone-input input {
  padding-left: 50px; /* Adjust this to match the width of the country code */
}
.react-tel-input .form-control {
  padding-left: 60px !important; /* Adjust this padding as needed */
}
.react-tel-input .flag-dropdown {
  left: 0;
  margin-right: 10px;
  color: black;
}

.subscription-text {
  font-size: 2rem; /* Bold large text size */
  font-weight: 700; /* Bold text */
  text-transform: uppercase; /* Uppercase text */
  background-image: linear-gradient(45deg); /* Gold gradient */
  -webkit-background-clip: text; /* Clip gradient to the text */
  color: transparent; /* Make the text color transparent to show the gradient */
  transition: all 0.3s ease-in-out;
}

.bg-platinum {
  background-image: linear-gradient(
    45deg,
    #8722cf,
    #9b43e7
  ); /* Purple gradient */
}

.bg-gold {
  background-image: linear-gradient(
    45deg,
    #e3a403,
    #f48c06
  ); /* Gold gradient */
}

.bg-silver {
  background-image: linear-gradient(
    45deg,
    #00b4d8,
    #0077b6
  ); /* Blue gradient */
}

.bg-free {
  background-color: #6c757d; /* Gray text color for Free */
  color: white; /* White text color for Free */
}

/* Hover effect for a glowing effect */
.subscription-text:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  transform: scale(1.1);
}
/* Responsive Popup CSS */
/* Overlay to dim the background */
.popup-overlay-responsive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
}

/* Content container for the popup */
.popup-content-responsive {
  background: rgb(75, 75, 75);
  padding: 40px;
  border: #ff6c2f solid 2px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0); /* Add subtle shadow for emphasis */
  z-index: 10000; /* Ensure it stays on top of the overlay */
}

/* Popup text styling */
.popup-message-responsive {
  color: rgb(255, 255, 255);
  margin: 0;
  font-size: large;
}





/* responsive view */

/* For extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .button-box-upload {
    flex-direction: column;
    gap: 30px;
  }
  .marketing-plansTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
  }
  .PlanButtons {
    font-size: 14px;
  }
  .section2Title {
    width: 65%;
  }
  .marketing-plansbottom {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .marketingPlansTitleBox {
    width: 100%;
  }
  .marketingPlansTitle {
    font-size: 20px;
  }
}

/* For small devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .button-box-upload {
    flex-direction: column;
    gap: 30px;
  }
  .marketing-plansTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px;
  }
  .freePlanButton {
    font-size: 14px;
  }
  .marketing-plansbottom {
    gap: 10px;
    display: flex;
    /* flex-direction: column; */
  }
  .marketingPlansTitleBox {
    width: 100%;
  }
  .marketingPlansTitle {
    font-size: 20px;
  }
}

/* For medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .button-box-upload {
    flex-direction: column;
    gap: 30px;
  }
  .marketing-plansTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* width: 200px; */
  }
  .freePlanButton {
    font-size: 14px;
  }
  .marketing-plansbottom {
    gap: 10px;
    display: flex;
    /* flex-direction: column; */
  }
  .marketingPlansTitleBox {
    width: 100%;
  }
}

/* For large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your CSS here */
}

/* For extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Your CSS here */
}
